import PropTypes from 'prop-types';

// Utils
import { cn } from 'utils';

// Styles
import styles from './Title.module.css';

function Title({
  title, subtitle, theme, className, titleTag: TitleTag,
}) {
  return (
    <div className={cn([styles.wrapper, styles[theme]])}>
      <div className={cn([styles.container, className])}>
        {subtitle ? (
          <span className={styles.subtitle}>{subtitle}</span>
        ) : null}
        <TitleTag className={cn([styles.title, `${TitleTag}-desktop-default`, `${TitleTag}-mobile-default`])}>{title}</TitleTag>
      </div>
    </div>
  );
}

Title.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  className: PropTypes.string,
  titleTag: PropTypes.string,
};

Title.defaultProps = {
  title: '',
  subtitle: '',
  theme: 'light',
  className: '',
  titleTag: 'h2',
};

export default Title;
